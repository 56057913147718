import { useEffect } from "react";

const TooltipStyler = () => {
	useEffect(() => {
		const applyTooltipStyles = () => {
			const tooltips = Array.from(
				document.querySelectorAll(
					".ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right"
				)
			);

			tooltips.forEach((tooltip) => {
				if (tooltip?.innerText === "NikoIQ Exclusives") {
					tooltip.style.setProperty(
						"margin-top",
						"calc(830px / 0.75)",
						"important"
					);
					tooltip.style.setProperty(
						"margin-left",
						"calc(780px / 0.75)",
						"important"
					);
				} else if (tooltip?.innerText === "Esports") {
					tooltip.style.setProperty(
						"margin-top",
						"calc(930px / 0.75)",
						"important"
					);
					tooltip.style.setProperty(
						"margin-left",
						"calc(780px / 0.75)",
						"important"
					);
				} else if (tooltip?.innerText === "Market Model") {
					tooltip.style.setProperty(
						"margin-top",
						"calc(900px / 0.75)",
						"important"
					);
					tooltip.style.setProperty(
						"margin-left",
						"calc(780px / 0.75)",
						"important"
					);
				}
			});
		};

		// Create a MutationObserver to watch for changes in the DOM
		const observer = new MutationObserver(() => {
			// Use a timeout to delay applying styles
			setTimeout(applyTooltipStyles, 100); // Adjust delay as necessary
		});

		// Start observing the body for child node additions or removals
		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});

		// Initial call to set styles for any existing tooltips
		setTimeout(applyTooltipStyles, 100); // Initial delay

		// Cleanup observer on component unmount
		return () => observer.disconnect();
	}, []); // Empty dependency array ensures this runs once after component mounts

	return null;
};

export default TooltipStyler;
