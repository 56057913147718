import React, { useState, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import iconFilter from "@/assets/icon/icon_search.png";
import "./index.less";
import CustomEmpty from "@/utils/CustomEmpty";
import {
	Button,
	Image,
	Row,
	Col,
	Space,
	Tag,
	Card,
	Table,
	Form,
	Input,
	DatePicker,
	Select,
	Spin,
	Radio,
	InputNumber,
	Pagination,
	Typography,
	Collapse,
} from "antd";
import CustomModal from "../../../components/modal";
import sortData from "../../../utils/sorter";
import defaultLogo from "../../../assets/images/game/defaul-logo_game.png";
import { effect, useStore } from "@/utils/dva16";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
import { useMediaQuery } from "react-responsive";
import {
	EGet,
	EGetCollect,
	EGetCompany,
	NGame,
	NGenre,
	NOrganizationMulti,
	NPlatform,
	NInsightsFeed,
	NLogin,
	EGeTPage,
} from "../../../models/contants";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { customizeRenderEmpty } from "../../../utils/utils";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;
export default (props) => {
	const { Panel } = Collapse;
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
	const { history } = props;
	const [form] = Form.useForm();
	const { OrganizationCompany } = useStore(NOrganizationMulti);
	const [searchValue, setSearchValue] = useState(null);
	const { GenreList } = useStore(NGenre);
	const { PlatformData } = useStore(NPlatform);
	const [filterValues, setFilterValues] = useState([]);
	const { GameList, gameTitle, loading, gamePage } = useStore(NGame);
	const { EsportsList, InsightsFeedList } = useStore(NInsightsFeed);
	const { currentSubscribe, userInfo } = useStore(NLogin);

	const [sortState, setSortState] = useState(null);

	let sortType = {
		ascend: "asc",
		descend: "desc",
	};
	const [showAllTags, setShowAllTags] = useState({});
	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const [showAllTags2, setShowAllTags2] = useState({});
	const toggleShowAll2 = (index) => {
		setShowAllTags2((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const columns = [
		{
			title: "Game",
			dataIndex: "gameEn",
			key: "gameEn",
			showSorterTooltip: false,
			align: "left",
			width: 250,
			sortOrder: sortState?.field == "gameEn" && sortState?.order,
			sorter: (a, b) => a?.gameEn?.localeCompare(b?.gameEn),

			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<img
							className="Tabel_Company_Logo"
							src={record?.logo ? record.logo : defaultLogo}
							alt={record?.name}
						/>
						<div className="Tabel_Company_Title">
							<p>
								<a
									className="a_style"
									href={`#/games_organizations/games/details/${record?.id}`}
								>
									{record?.gameEn}
								</a>
							</p>
							<p>
								<a
									className="a_style"
									href={`#/games_organizations/games/details/${record?.id}`}
								>
									{record?.gameCn}
								</a>
							</p>
						</div>
					</div>
				</>
			),
		},
		{
			title: "Status",
			key: "status",
			showSorterTooltip: false,
			dataIndex: "status",
			align: "left",
			width: 100,
			sortOrder: sortState?.field == "status" && sortState?.order,
			sorter: (a, b) => a?.status?.localeCompare(b?.status),
		},
		{
			title: "Genre",
			dataIndex: "genre",
			key: "genre",
			align: "left",
			showSorterTooltip: false,
			width: 150,
			sortOrder: sortState?.field == "genre" && sortState?.order,
			sorter: (a, b) => a?.genre?.localeCompare(b?.genre),
			render: (text, value, index) => (
				<>
					{value?.genre?.length > 0 &&
						value?.genre?.map((item, index) => {
							return <div key={index}>{item + "\n"}</div>;
						})}
				</>
			),
		},

		{
			title: "Developer",
			dataIndex: "developer",
			key: "developer",
			className: "custom-publisher-column",
			align: "center",
			showSorterTooltip: false,
			width: 150,
			sortOrder: sortState?.field == "developer" && sortState?.order,
			sorter: (a, b) => {
				a?.withDeveloper?.[0]?.name &&
					b?.withDeveloper?.[0]?.name &&
					a?.withDeveloper?.[0]?.name?.localeCompare(
						b?.withDeveloper?.[0]?.name
					);
			},
			render: (text, value, index) => {
				const showAll = showAllTags[index] || false;
				return (
					<div style={{ wordBreak: "break-all" }}>
						{value?.withDeveloper?.length > 0 &&
						Array.isArray(value?.withDeveloper)
							? value?.withDeveloper
									?.slice(0, showAll ? value.withDeveloper.length : 3)
									?.map((item, key) => {
										return (
											<Tag
												className="purbleTag"
												key={key}
												onClick={() => {
													toDetails(item?.id);
												}}
											>
												{item?.name}

												{key + 1 != value?.withDeveloper?.length ? "," : ""}
											</Tag>
										);
									})
							: value?.withDeveloper}

						{value?.withDeveloper?.length != 0 ? "" : "-"}
						{(value?.withLicensee?.length > 3 ||
							value?.withDeveloper?.length > 3 ||
							value?.withTeams?.length > 3 ||
							value?.withPlatforms?.length > 3 ||
							value?.genres?.length > 3) && (
							<Tag className="greyTag" onClick={() => toggleShowAll(index)}>
								{showAll ? "Show less..." : "Show all..."}
							</Tag>
						)}
					</div>
				);
			},
		},
		{
			title: "Publisher",
			dataIndex: "publisher",
			key: "publisher",
			showSorterTooltip: false,
			align: "center",
			className: "custom-publisher-column",
			width: 150,
			sortOrder: sortState?.field == "publisher" && sortState?.order,
			sorter: (a, b) => {
				a?.withPublisher?.[0]?.name &&
					b?.withPublisher?.[0]?.name &&
					a?.withPublisher?.[0]?.name?.localeCompare(
						b?.withPublisher?.[0]?.name
					);
			},
			render: (text, value, index) => {
				const showAll = showAllTags2[index] || false;
				return (
					<div style={{ wordBreak: "break-all" }}>
						{value?.withPublisher?.length > 0 &&
						Array.isArray(value?.withPublisher)
							? value?.withPublisher
									?.slice(0, showAll ? value.withPublisher.length : 3)
									?.map((item, key) => {
										return (
											<Tag
												className="purbleTag"
												key={key}
												onClick={() => {
													toDetails(item?.id);
												}}
											>
												{item?.name}
												{key + 1 != value?.withPublisher?.length ? "," : ""}
											</Tag>
										);
									})
							: value?.withPublisher}
						{value?.withPublisher?.length != 0 ? "" : "-"}
						{(value?.withPublisher?.length > 3 ||
							value?.withCompanys?.length > 3 ||
							value?.withTeams?.length > 3 ||
							value?.withPlatforms?.length > 3 ||
							value?.genres?.length > 3) && (
							<Tag className="greyTag" onClick={() => toggleShowAll2(index)}>
								{showAll ? "Show less..." : "Show all..."}
							</Tag>
						)}
					</div>
				);
			},
		},
		{
			title: "Platform",
			dataIndex: "platform",
			key: "platform",
			align: "left",
			showSorterTooltip: false,
			width: 100,
			sortOrder: sortState?.field == "platform" && sortState?.order,
			sorter: (a, b) =>
				a?.platform &&
				b?.platform &&
				a?.platform?.[0]?.localeCompare(b?.platform?.[0]),
			render: (text, value, index) => (
				<>
					{value?.platform?.length > 0 &&
						value?.platform?.map((item, index) => {
							return <div key={index}>{item + "\n"}</div>;
						})}
				</>
			),
		},
		{
			title: "No. of ISBN",
			key: "numOfIsbn",
			dataIndex: "numOfIsbn",
			showSorterTooltip: false,
			align: "center",
			sorter: true,
			align: "left",
			width: 100,
			sortOrder: sortState?.field == "numOfIsbn" && sortState?.order,
		},

		{
			title: "Information",
			dataIndex: "information",
			showSorterTooltip: false,
			key: "information",
			align: "left",
			width: 100,
			render: (text, value, index) => (
				<div className="Tabel_Information">
					{value?.__meta__?.exclusives_count >= 1 && (
						<a
							style={
								value?.__meta__?.exclusives_count >= 1
									? {}
									: {
											color: "black",
											textDecoration: "none",
											cursor: "default",
									  }
							}
							className="a_style"
							onClick={() => {
								if (value?.__meta__?.exclusives_count >= 1) {
									history.push({
										pathname: "/nikoiq_exclusives",
										state: { game: value?.id },
									});
								}
							}}
						>
							Exclusive
						</a>
					)}

					{value?.__meta__?.InsightFeed_count >= 1 && (
						<a
							style={
								value?.__meta__?.InsightFeed_count >= 1
									? {}
									: {
											color: "black",
											textDecoration: "none",
											cursor: "default",
									  }
							}
							className="a_style"
							onClick={() => {
								if (value?.__meta__?.InsightFeed_count >= 1) {
									history.push({
										pathname: "/news_articles/VANA",
										state: { game: value?.id },
									});
								}
							}}
						>
							VANA
						</a>
					)}

					{value?.__meta__?.Tournament_count >= 1 && (
						<a
							style={
								value?.__meta__?.Tournament_count >= 1
									? {}
									: {
											color: "black",
											textDecoration: "none",
											cursor: "default",
									  }
							}
							className="a_style"
							onClick={() => {
								if (value?.__meta__?.Tournament_count >= 1) {
									history.push({
										pathname: "/Esports",
										state: { game: value?.id },
									});
								}
							}}
						>
							Tournaments
						</a>
					)}
				</div>
			),
		},
		// {
		// 	title: "Release Date",
		// 	dataIndex: "publishedAt",
		// 	key: "publishedAt",
		// 	render: (text, value, index) => (
		// 		<>{text ? `${moment(text)?.format("YYYY-MM-DD")}` : "-"}</>
		// 	),
		// 	sortOrder: sortState?.field == "publishedAt" && sortState?.order,
		// 	sorter: (a, b) => {
		// 		const dateA = new Date(a?.publishedAt);
		// 		const dateB = new Date(b?.publishedAt);
		// 		return dateA - dateB;
		// 	},
		// },
	];

	useEffect(() => {
		if (!GameList) {
			effect(NGame, EGetCollect, { page: 1, perPage: 10 });
			effect(NOrganizationMulti, EGetCompany, { page: 1, perPage: 10 });
			effect(NGenre, EGet, { page: 1, perPage: 10 });
			effect(NPlatform, EGet, { page: 1, perPage: 10 });
			effect(NLogin, EGet, {});
		}
		return () => {};
	}, []);
	useEffect(() => {
		if (!GameList) {
			effect(NGame, EGet, { page: gamePage ? gamePage : 1, perPage: 10 });
		}
	}, [gamePage]);
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetCompany, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};
	const toDetails = (value) => {
		history.push(`/games_organizations/organizations/details/${value}`);
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const onFinish = (values) => {
		values.platform = values?.platform?.map((platform) => {
			return PlatformData?.data?.find((item) => item?.lookupValue === platform)
				?.id;
		});
		values.genre = values?.genre?.map((genre) => {
			return GenreList?.data?.find((item) => item?.lookupValue === genre)?.id;
		});
		values.startDate = values?.Date
			? moment(values?.Date[0])?.format("YYYY-MM-DD")
			: null;
		values.endDate = values?.Date
			? moment(values?.Date[1])?.format("YYYY-MM-DD")
			: null;
		delete values?.Date;
		setSearchValue(values);
		const valuesArray = Object.values(values).filter(
			(val) =>
				val !== undefined &&
				val !== null &&
				!(Array.isArray(val) && val.length === 0)
		);
		setFilterValues(valuesArray);
		closeModal();
		effect(NGame, EGet, {
			...values,
			page: 1,
			perPage: 10,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
		});
	};
	const onReset = () => {
		setSearchValue();
		setFormValues([]);
		setFilterValues([]);
		effect(NGame, EGet, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGetCompany, { page: 1, perPage: 10 });
		effect(NGenre, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 10 });
		closeModal();
		form.resetFields();
	};
	const paginationLocale = {
		items_per_page: "per page",
	};
	const onPageChange = (page, pageSize, sort) => {
		if (page && pageSize) {
			effect(NGame, EGet, {
				...searchValue,
				page: page,
				perPage: pageSize,
				field: sortState && sortState?.field,
				isDesc: (sortState && sortState?.order) || null,
			});
		}
	};
	const [formValues, setFormValues] = useState({});

	// Handle form value changes
	const handleValuesChange = (changedValues) => {
		setFormValues((prevValues) => ({
			...prevValues,
			...changedValues,
		}));
	};

	// Reset field function
	const resetField = (fieldName) => {
		if (fieldName === "area") {
			findRegionType([]); // Clear region based on empty area
		}
		form.setFieldsValue({ [fieldName]: [] });
		setFormValues((prevValues) => ({
			...prevValues,
			[fieldName]: [],
		}));
		let values = form.getFieldsValue();
		setFilterValues(values);
	};
	const [activeKey, setActiveKey] = useState([]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};
	// ---------------------渲染-------------------------
	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<div>
				<p className="mainheader">Games</p>
				<div className="subHeader">
					Filter the knowledge base for Game data.
				</div>
			</div>
			{userInfo?.type == "Client" ? (
				currentSubscribe?.[0]?.module.hasOwnProperty("Games") &&
				currentSubscribe?.[0]?.module["Games"]?.length > 0 ? (
					<>
						<div className="searchBlog" style={{}}>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<Form
									onValuesChange={handleValuesChange}
									form={form}
									onFinish={onFinish}
									className="searchFormCard"
								>
									{" "}
									<Row
										style={{
											display: "flex",
											justifyContent: "flex-end",
											alignItems: "center",
										}}
									>
										<Form.Item name="keyword" labelCol={{ span: 24 }}>
											<Input
												className="customInputBlog"
												placeholder="Search by Game"
											/>
										</Form.Item>
										<Button
											type="primary"
											htmlType="submit"
											className="applySearchBtn"
										>
											<img src={iconFilter} />
										</Button>
									</Row>
								</Form>
							</div>
							<CustomModal
								filterValues={filterValues}
								isModalOpen={isModalOpen}
								showModal={showModal}
								closeModal={closeModal}
								content={
									<>
										{" "}
										<Form
											onValuesChange={handleValuesChange}
											name="advanced_search"
											className="ant-advanced-search-form"
											form={form}
											onFinish={onFinish}
										>
											<Row>
												<Col span={24}>
													<Form.Item
														name="keyword"
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	GAME
																</Text>
																<Button
																	disabled={
																		!formValues.keyword ||
																		formValues.keyword.length === 0
																	}
																	className={
																		!formValues.keyword ||
																		formValues.keyword.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("keyword")}
																	type="secondary"
																	style={{
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
														rules={[
															{
																required: false,
																message: "Input something!",
															},
														]}
													>
														<Input
															placeholder="Input here"
															autoComplete="off"
															// style={{ width: "80%" }}
														/>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Form.Item
														name="genre"
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	GENRE
																</Text>
																<Button
																	disabled={
																		!formValues.genre ||
																		formValues.genre.length === 0
																	}
																	className={
																		!formValues.genre ||
																		formValues.genre.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("genre")}
																	type="secondary"
																	style={{
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
													>
														<Select
															// style={{ width: "80%" }}

															getPopupContainer={(triggerNode) =>
																triggerNode?.parentNode
															}
															showArrow
															showSearch
															filterOption={false}
															placeholder="Select"
															onSearch={debounce((value) => {
																onSearchGenre(value);
															}, 300)}
															mode="multiple"
														>
															{sortData(GenreList?.data, "genre")?.map(
																(item, index) => {
																	return (
																		<Option
																			key={index}
																			value={item?.lookupValue}
																		>
																			{item?.lookupValue}
																		</Option>
																	);
																}
															)}
														</Select>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Form.Item
														name="developer"
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	DEVELOPER
																</Text>
																<Button
																	disabled={
																		!formValues.developer ||
																		formValues.developer.length === 0
																	}
																	className={
																		!formValues.developer ||
																		formValues.developer.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("developer")}
																	type="secondary"
																	style={{
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
													>
														<Select
															// style={{ width: "80%" }}

															getPopupContainer={(triggerNode) =>
																triggerNode?.parentNode
															}
															showArrow
															showSearch
															filterOption={false}
															placeholder="Select"
															onSearch={debounce((value) => {
																onSearchCompany(value);
															}, 300)}
														>
															{sortData(OrganizationCompany?.data)?.map(
																(item, index) => {
																	return (
																		<Option key={index} value={item?.id}>
																			{item?.name}
																		</Option>
																	);
																}
															)}
														</Select>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Form.Item
														name="publisher"
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	PUBLISHER
																</Text>
																<Button
																	disabled={
																		!formValues.publisher ||
																		formValues.publisher.length === 0
																	}
																	className={
																		!formValues.publisher ||
																		formValues.publisher.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("publisher")}
																	type="secondary"
																	style={{
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
													>
														<Select
															// style={{ width: "79%" }}

															getPopupContainer={(triggerNode) =>
																triggerNode?.parentNode
															}
															showSearch
															showArrow
															filterOption={false}
															placeholder="Select"
															onSearch={debounce((value) => {
																onSearchCompany(value);
															}, 300)}
														>
															{sortData(OrganizationCompany?.data)?.map(
																(item, index) => {
																	return (
																		<Option key={index} value={item?.id}>
																			{item?.name}
																		</Option>
																	);
																}
															)}
														</Select>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Form.Item
														name="platform"
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	PLATFORM
																</Text>
																<Button
																	disabled={
																		!formValues.platform ||
																		formValues.platform.length === 0
																	}
																	className={
																		!formValues.platform ||
																		formValues.platform.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("platform")}
																	type="secondary"
																	style={{
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
														rules={[
															{
																required: false,
																message: "Input something!",
															},
														]}
													>
														<Select
															showArrow
															// style={{ width: "79%" }}
															mode="multiple"
															getPopupContainer={(triggerNode) =>
																triggerNode?.parentNode
															}
															showSearch
															filterOption={false}
															placeholder="Select"
															onSearch={debounce((value) => {
																onSearchPlatform(value);
															}, 300)}
														>
															{sortData(PlatformData?.data, "platform")?.map(
																(record, index) => {
																	return (
																		<Option
																			key={index}
																			value={record?.lookupValue}
																		>
																			{record?.lookupValue}
																		</Option>
																	);
																}
															)}
														</Select>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Form.Item
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	NO. OF ISBNs
																</Text>
																<Button
																	disabled={
																		!formValues.numOfIsbn ||
																		formValues.numOfIsbn.length === 0
																	}
																	className={
																		!formValues.numOfIsbn ||
																		formValues.numOfIsbn.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("numOfIsbn")}
																	type="secondary"
																	style={{
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
														name="numOfIsbn"
													>
														<Select
															showArrow
															placeholder="Select "
															getPopupContainer={(triggerNode) =>
																triggerNode.parentNode
															}
															// style={{ width: "79%" }}
														>
															<Option value={"0"}>0</Option>
															<Option value={"1"}>1</Option>
															<Option value={"2"}>2</Option>
															<Option value={"3"}>3</Option>
															<Option value={"4"}>4</Option>
															<Option value={"5"}>5</Option>
															<Option value={">5"}>{">5"}</Option>
														</Select>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Form.Item
														labelCol={{ span: 24 }}
														label={
															<div className="headBox">
																<Text className="label" type="secondary">
																	STATUS
																</Text>
																<Button
																	disabled={
																		!formValues.status ||
																		formValues.status.length === 0
																	}
																	className={
																		!formValues.status ||
																		formValues.status.length === 0
																			? "resetDisabled"
																			: "reset"
																	}
																	onClick={() => resetField("status")}
																	type="secondary"
																	style={{
																		cursor: "pointer",
																	}}
																>
																	Reset
																</Button>
															</div>
														}
														name="status"
													>
														<Radio.Group className="custom-radio">
															<Radio className="custom-radio-item" value={""}>
																All
															</Radio>
															<Radio
																className="custom-radio-item"
																value={"Released"}
															>
																Released
															</Radio>
															<Radio
																className="custom-radio-item"
																value={"Retired"}
															>
																Retired
															</Radio>
															<Radio
																style={{ marginTop: "-9px" }}
																className="custom-radio-item"
																value={"In Development / Testing"}
															>
																In Development / Testing
															</Radio>
														</Radio.Group>
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={24} style={{ textAlign: "right" }}>
													<Space>
														<Button
															type="primary"
															htmlType="submit"
															className="applyBtn"
														>
															Apply Now
														</Button>
														<Button
															type="default"
															onClick={onReset}
															className="resetBtn"
														>
															Reset All
														</Button>
													</Space>
												</Col>
											</Row>
										</Form>
									</>
								}
							/>
						</div>

						<Spin spinning={loading} size="large">
							<div className="GameBox">
								<Row className="RowBox mt30">
									<Col span={24}>
										{!isMobile ? (
											<Table
												locale={{ emptyText: CustomEmpty }}
												scroll={{ x: "max-content" }}
												dataSource={GameList?.data || []}
												columns={columns}
												className="CompanyTableBox"
												pagination={false} // Hide pagination
												onChange={(pagination, filters, sort) => {
													const pageSize = GameList?.perPage || 10; // Get the pageSize from your data or default
													const currentPage = GameList?.page || 1; // Get the current page from your data or default
													console.log(GameList);
													setSortState(sort);
													effect(NGame, EGet, {
														...searchValue,
														perPage: pageSize,
														page: currentPage,
														field: sort?.column && sort?.field,
														isDesc:
															(sort?.column &&
																sort?.order &&
																sortType[sort?.order]) ||
															null,
													});
													sessionStorage.setItem("orgPage", pagination?.page);
													sessionStorage.setItem(
														"orgPageSize",
														pagination?.pageSize
													);
												}}
											/>
										) : (
											<Collapse
												accordion
												activeKey={activeKey}
												onChange={handleCollapseChange}
												className="MobileList"
												expandIconPosition="right"
												expandIcon={({ isActive }) => (
													<div className="custom-arrow">
														<img
															src={isActive ? reverseArrow : iconArrow}
															alt="arrow"
														/>
													</div>
												)}
											>
												{GameList?.data.map((record) => (
													<Panel
														header={
															<div className="panel-header">
																<div style={{ display: "flex" }}>
																	{
																		<img
																			src={
																				record?.logo ? record.logo : defaultLogo
																			} // Assuming item has an imageSrc property
																			alt="tournament"
																			className="tournament-image"
																		/>
																	}
																	<a
																		className="a_style"
																		href={`#/games_organizations/games/details/${record?.id}`}
																	>
																		<div>
																			{" "}
																			<div>{record?.gameEn}</div>
																			<div>{record?.gameCn}</div>
																		</div>
																	</a>
																</div>
															</div>
														}
														key={record.id}
													>
														<p>
															<Row>
																<Col className="head" span={12}>
																	Status
																</Col>
																<Col className="text" span={12}>
																	<>
																		<div className="Tabel_Company">
																			<span>{record?.status || "-"}</span>
																		</div>
																	</>
																</Col>
															</Row>
															<Row>
																<Col className="head" span={12}>
																	Genre
																</Col>
																<Col className="text" span={12}>
																	<>
																		{record?.genre?.length > 0 &&
																			record?.genre?.map((item, index) => {
																				return (
																					<div key={index}>{item + "\n"}</div>
																				);
																			})}
																	</>
																</Col>
															</Row>
															<Row>
																<Col className="head" span={12}>
																	Developer
																</Col>
																<Col span={12}>
																	<div style={{ wordBreak: "break-all" }}>
																		{record?.withDeveloper?.length > 0 &&
																		Array.isArray(record?.withDeveloper)
																			? record?.withDeveloper?.map(
																					(item, key) => {
																						return (
																							<Tag
																								className="purbleTag"
																								key={key}
																								onClick={() => {
																									toDetails(item?.id);
																								}}
																							>
																								{item?.name}

																								{key + 1 !=
																								record?.withDeveloper?.length
																									? ","
																									: ""}
																							</Tag>
																						);
																					}
																			  )
																			: record?.withDeveloper}
																		{record?.withDeveloper?.length != 0
																			? ""
																			: "-"}
																	</div>
																</Col>
															</Row>
															<Row>
																<Col className="head" span={12}>
																	Publisher
																</Col>
																<Col className="text" span={12}>
																	<div style={{ wordBreak: "break-all" }}>
																		{record?.withPublisher?.length > 0 &&
																		Array.isArray(record?.withPublisher)
																			? record?.withPublisher?.map(
																					(item, key) => {
																						return (
																							<Tag
																								className="purbleTag"
																								key={key}
																								onClick={() => {
																									toDetails(item?.id);
																								}}
																							>
																								{item?.name}
																								{key + 1 !=
																								record?.withPublisher?.length
																									? ","
																									: ""}
																							</Tag>
																						);
																					}
																			  )
																			: record?.withPublisher}
																		{record?.withPublisher?.length != 0
																			? ""
																			: "-"}
																	</div>
																</Col>
															</Row>
															<Row>
																<Col className="head" span={12}>
																	Platforms
																</Col>
																<Col className="text" span={12}>
																	<>
																		{record?.platform?.length > 0 &&
																			record?.platform?.map((item, index) => {
																				return (
																					<div key={index}>{item + "\n"}</div>
																				);
																			})}
																	</>
																</Col>
															</Row>
															<Row>
																<Col className="head" span={12}>
																	No. of ISBN
																</Col>
																<Col className="text" span={12}>
																	<>
																		<div className="Tabel_Company">
																			<span>{record?.numOfIsbn || "-"}</span>
																		</div>
																	</>
																</Col>
															</Row>
															<Row>
																<Col className="head" span={12}>
																	Information
																</Col>
																<Col className="text" span={12}>
																	<div className="Tabel_Information">
																		{record?.__meta__?.exclusives_count >=
																			1 && (
																			<a
																				style={
																					record?.__meta__?.exclusives_count >=
																					1
																						? { margin: "5px" }
																						: {
																								color: "black",
																								textDecoration: "none",
																								cursor: "default",
																								margin: "5px",
																						  }
																				}
																				className="a_style"
																				onClick={() => {
																					if (
																						record?.__meta__
																							?.exclusives_count >= 1
																					) {
																						history.push({
																							pathname: "/nikoiq_exclusives",
																							state: { game: record?.id },
																						});
																					}
																				}}
																			>
																				Exclusive
																			</a>
																		)}

																		{record?.__meta__?.InsightFeed_count >=
																			1 && (
																			<a
																				style={
																					record?.__meta__?.InsightFeed_count >=
																					1
																						? { margin: "5px" }
																						: {
																								color: "black",
																								textDecoration: "none",
																								cursor: "default",
																								margin: "5px",
																						  }
																				}
																				className="a_style"
																				onClick={() => {
																					if (
																						record?.__meta__
																							?.InsightFeed_count >= 1
																					) {
																						history.push({
																							pathname: "/news_articles/VANA",
																							state: { game: record?.id },
																						});
																					}
																				}}
																			>
																				VANA
																			</a>
																		)}

																		{record?.__meta__?.Tournament_count >=
																			1 && (
																			<a
																				style={
																					record?.__meta__?.Tournament_count >=
																					1
																						? { margin: "5px" }
																						: {
																								color: "black",
																								textDecoration: "none",
																								cursor: "default",
																								margin: "5px",
																						  }
																				}
																				className="a_style"
																				onClick={() => {
																					if (
																						record?.__meta__
																							?.Tournament_count >= 1
																					) {
																						history.push({
																							pathname: "/Esports",
																							state: { game: record?.id },
																						});
																					}
																				}}
																			>
																				Tournaments
																			</a>
																		)}
																	</div>
																</Col>
															</Row>
														</p>
													</Panel>
												))}
											</Collapse>
										)}
									</Col>
								</Row>
								<Row style={{ padding: "10px 10px 0 10px", width: "100%" }}>
									<Col span={24}>
										{GameList?.error != 1009 && GameList?.total
											? GameList?.total && (
													<Pagination
														current={parseInt(GameList && GameList?.page) || 1}
														total={(GameList && GameList?.total) || 0}
														pageSize={GameList && GameList?.perPage}
														onChange={onPageChange}
														showSizeChanger
														showQuickJumper
														showTotal={(total, range) =>
															`${range[0]}-${range[1]} of ${total} items`
														}
														locale={paginationLocale}
														itemRender={(page, type, originalElement) => {
															if (type === "page") {
																return <span>{page}</span>;
															}
															return originalElement;
														}}
														className="custom-pagination"
													/>
											  )
											: ""}
									</Col>
								</Row>
							</div>
						</Spin>
					</>
				) : (
					customizeRenderEmpty()
				)
			) : (
				<>
					<div className="searchBlog" style={{}}>
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<Form
								onValuesChange={handleValuesChange}
								form={form}
								onFinish={onFinish}
								className="searchFormCard"
							>
								{" "}
								<Row
									style={{
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<Form.Item name="keyword" labelCol={{ span: 24 }}>
										<Input
											className="customInputBlog"
											placeholder="Search by Game"
										/>
									</Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="applySearchBtn"
									>
										<img src={iconFilter} />
									</Button>
								</Row>
							</Form>
						</div>
						<CustomModal
							filterValues={filterValues}
							isModalOpen={isModalOpen}
							showModal={showModal}
							closeModal={closeModal}
							content={
								<>
									{" "}
									<Form
										onValuesChange={handleValuesChange}
										name="advanced_search"
										className="ant-advanced-search-form"
										form={form}
										onFinish={onFinish}
									>
										<Row>
											<Col span={24}>
												<Form.Item
													name="keyword"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																GAME
															</Text>
															<Button
																disabled={
																	!formValues.keyword ||
																	formValues.keyword.length === 0
																}
																className={
																	!formValues.keyword ||
																	formValues.keyword.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("keyword")}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
												>
													<Input
														placeholder="Input here"
														autoComplete="off"
														// style={{ width: "80%" }}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													name="genre"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																GENRE
															</Text>
															<Button
																disabled={
																	!formValues.genre ||
																	formValues.genre.length === 0
																}
																className={
																	!formValues.genre ||
																	formValues.genre.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("genre")}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														// style={{ width: "80%" }}

														showArrow
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchGenre(value);
														}, 300)}
														mode="multiple"
													>
														{sortData(GenreList?.data, "genre")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.lookupValue}>
																		{item?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													name="developer"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																DEVELOPER
															</Text>
															<Button
																disabled={
																	!formValues.developer ||
																	formValues.developer.length === 0
																}
																className={
																	!formValues.developer ||
																	formValues.developer.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("developer")}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														// style={{ width: "80%" }}

														showArrow
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchCompany(value);
														}, 300)}
													>
														{sortData(OrganizationCompany?.data)?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.id}>
																		{item?.name}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													name="publisher"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																PUBLISHER
															</Text>
															<Button
																disabled={
																	!formValues.publisher ||
																	formValues.publisher.length === 0
																}
																className={
																	!formValues.publisher ||
																	formValues.publisher.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("publisher")}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														// style={{ width: "79%" }}

														showArrow
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchCompany(value);
														}, 300)}
													>
														{sortData(OrganizationCompany?.data)?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.id}>
																		{item?.name}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													name="platform"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																PLATFORM
															</Text>
															<Button
																disabled={
																	!formValues.platform ||
																	formValues.platform.length === 0
																}
																className={
																	!formValues.platform ||
																	formValues.platform.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("platform")}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
												>
													<Select
														showArrow
														// style={{ width: "79%" }}
														mode="multiple"
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchPlatform(value);
														}, 300)}
													>
														{sortData(PlatformData?.data, "platform")?.map(
															(record, index) => {
																return (
																	<Option
																		key={index}
																		value={record?.lookupValue}
																	>
																		{record?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																NO. OF ISBNs
															</Text>
															<Button
																disabled={
																	!formValues.numOfIsbn ||
																	formValues.numOfIsbn.length === 0
																}
																className={
																	!formValues.numOfIsbn ||
																	formValues.numOfIsbn.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("numOfIsbn")}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
													name="numOfIsbn"
												>
													<Select
														showArrow
														placeholder="Select "
														getPopupContainer={(triggerNode) =>
															triggerNode.parentNode
														}
														// style={{ width: "79%" }}
													>
														<Option value={"0"}>0</Option>
														<Option value={"1"}>1</Option>
														<Option value={"2"}>2</Option>
														<Option value={"3"}>3</Option>
														<Option value={"4"}>4</Option>
														<Option value={"5"}>5</Option>
														<Option value={">5"}>{">5"}</Option>
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Form.Item
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																STATUS
															</Text>
															<Button
																disabled={
																	!formValues.status ||
																	formValues.status.length === 0
																}
																className={
																	!formValues.status ||
																	formValues.status.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("status")}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
													name="status"
												>
													<Radio.Group className="custom-radio">
														<Radio className="custom-radio-item" value={""}>
															All
														</Radio>
														<Radio
															className="custom-radio-item"
															value={"Released"}
														>
															Released
														</Radio>
														<Radio
															className="custom-radio-item"
															value={"Retired"}
														>
															Retired
														</Radio>
														<Radio
															style={{ marginTop: "-9px" }}
															className="custom-radio-item"
															value={"In Development / Testing"}
														>
															In Development / Testing
														</Radio>
													</Radio.Group>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											<Col span={24} style={{ textAlign: "right" }}>
												<Space>
													<Button
														type="primary"
														htmlType="submit"
														className="applyBtn"
													>
														Apply Now
													</Button>
													<Button
														type="default"
														onClick={onReset}
														className="resetBtn"
													>
														Reset All
													</Button>
												</Space>
											</Col>
										</Row>
									</Form>
								</>
							}
						/>
					</div>

					<Spin spinning={loading} size="large">
						<div className="GameBox">
							<Row className="RowBox mt30">
								<Col span={24}>
									{!isMobile ? (
										<Table
											locale={{ emptyText: CustomEmpty }}
											scroll={{ x: "max-content" }}
											dataSource={GameList?.data || []}
											columns={columns}
											className="CompanyTableBox"
											pagination={false} // Hide pagination
											onChange={(pagination, filters, sort) => {
												const pageSize = GameList?.perPage || 10; // Get the pageSize from your data or default
												const currentPage = GameList?.page || 1; // Get the current page from your data or default
												console.log(GameList);
												setSortState(sort);
												console.log(sort);
												effect(NGame, EGet, {
													...searchValue,
													perPage: pageSize,
													page: currentPage,
													field: sort?.column && sort?.field,
													isDesc:
														(sort?.column &&
															sort?.order &&
															sortType[sort?.order]) ||
														null,
												});
												sessionStorage.setItem("orgPage", pagination?.page);
												sessionStorage.setItem(
													"orgPageSize",
													pagination?.pageSize
												);
											}}
										/>
									) : (
										<Collapse
											accordion
											activeKey={activeKey}
											onChange={handleCollapseChange}
											className="MobileList"
											expandIconPosition="right"
											expandIcon={({ isActive }) => (
												<div className="custom-arrow">
													<img
														src={isActive ? reverseArrow : iconArrow}
														alt="arrow"
													/>
												</div>
											)}
										>
											{GameList?.data.map((record) => (
												<Panel
													header={
														<div className="panel-header">
															<div style={{ display: "flex" }}>
																{
																	<img
																		src={
																			record?.logo ? record.logo : defaultLogo
																		} // Assuming item has an imageSrc property
																		alt="tournament"
																		className="tournament-image"
																	/>
																}
																<a
																	className="a_style"
																	href={`#/games_organizations/games/details/${record?.id}`}
																>
																	<div>
																		{" "}
																		<div>{record?.gameEn}</div>
																		<div>{record?.gameCn}</div>
																	</div>
																</a>
															</div>
														</div>
													}
													key={record.id}
												>
													<p>
														<Row>
															<Col className="head" span={12}>
																Status
															</Col>
															<Col className="text" span={12}>
																<>
																	<div className="Tabel_Company">
																		<span>{record?.status || "-"}</span>
																	</div>
																</>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Genre
															</Col>
															<Col className="text" span={12}>
																<>
																	{record?.genre?.length > 0 &&
																		record?.genre?.map((item, index) => {
																			return (
																				<div key={index}>{item + "\n"}</div>
																			);
																		})}
																</>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Developer
															</Col>
															<Col span={12}>
																<div style={{ wordBreak: "break-all" }}>
																	{record?.withDeveloper?.length > 0 &&
																	Array.isArray(record?.withDeveloper)
																		? record?.withDeveloper?.map(
																				(item, key) => {
																					return (
																						<Tag
																							className="purbleTag"
																							key={key}
																							onClick={() => {
																								toDetails(item?.id);
																							}}
																						>
																							{item?.name}

																							{key + 1 !=
																							record?.withDeveloper?.length
																								? ","
																								: ""}
																						</Tag>
																					);
																				}
																		  )
																		: record?.withDeveloper}
																	{record?.withDeveloper?.length != 0
																		? ""
																		: "-"}
																</div>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Publisher
															</Col>
															<Col className="text" span={12}>
																<div style={{ wordBreak: "break-all" }}>
																	{record?.withPublisher?.length > 0 &&
																	Array.isArray(record?.withPublisher)
																		? record?.withPublisher?.map(
																				(item, key) => {
																					return (
																						<Tag
																							className="purbleTag"
																							key={key}
																							onClick={() => {
																								toDetails(item?.id);
																							}}
																						>
																							{item?.name}
																							{key + 1 !=
																							record?.withPublisher?.length
																								? ","
																								: ""}
																						</Tag>
																					);
																				}
																		  )
																		: record?.withPublisher}
																	{record?.withPublisher?.length != 0
																		? ""
																		: "-"}
																</div>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Platforms
															</Col>
															<Col className="text" span={12}>
																<>
																	{record?.platform?.length > 0 &&
																		record?.platform?.map((item, index) => {
																			return (
																				<div key={index}>{item + "\n"}</div>
																			);
																		})}
																</>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																No. of ISBN
															</Col>
															<Col className="text" span={12}>
																<>
																	<div className="Tabel_Company">
																		<span>{record?.numOfIsbn || "-"}</span>
																	</div>
																</>
															</Col>
														</Row>
														<Row>
															<Col className="head" span={12}>
																Information
															</Col>
															<Col className="text" span={12}>
																<div className="Tabel_Information">
																	{record?.__meta__?.exclusives_count >= 1 && (
																		<a
																			style={
																				record?.__meta__?.exclusives_count >= 1
																					? { margin: "5px" }
																					: {
																							color: "black",
																							textDecoration: "none",
																							cursor: "default",
																							margin: "5px",
																					  }
																			}
																			className="a_style"
																			onClick={() => {
																				if (
																					record?.__meta__?.exclusives_count >=
																					1
																				) {
																					history.push({
																						pathname: "/nikoiq_exclusives",
																						state: { game: record?.id },
																					});
																				}
																			}}
																		>
																			Exclusive
																		</a>
																	)}

																	{record?.__meta__?.InsightFeed_count >= 1 && (
																		<a
																			style={
																				record?.__meta__?.InsightFeed_count >= 1
																					? { margin: "5px" }
																					: {
																							color: "black",
																							textDecoration: "none",
																							cursor: "default",
																							margin: "5px",
																					  }
																			}
																			className="a_style"
																			onClick={() => {
																				if (
																					record?.__meta__?.InsightFeed_count >=
																					1
																				) {
																					history.push({
																						pathname: "/news_articles/VANA",
																						state: { game: record?.id },
																					});
																				}
																			}}
																		>
																			VANA
																		</a>
																	)}

																	{record?.__meta__?.Tournament_count >= 1 && (
																		<a
																			style={
																				record?.__meta__?.Tournament_count >= 1
																					? { margin: "5px" }
																					: {
																							color: "black",
																							textDecoration: "none",
																							cursor: "default",
																							margin: "5px",
																					  }
																			}
																			className="a_style"
																			onClick={() => {
																				if (
																					record?.__meta__?.Tournament_count >=
																					1
																				) {
																					history.push({
																						pathname: "/Esports",
																						state: { game: record?.id },
																					});
																				}
																			}}
																		>
																			Tournaments
																		</a>
																	)}
																</div>
															</Col>
														</Row>
													</p>
												</Panel>
											))}
										</Collapse>
									)}
								</Col>
							</Row>
							<Row style={{ padding: "10px 10px 0 10px", width: "100%" }}>
								<Col span={24}>
									{GameList?.error != 1009 && GameList?.total
										? GameList?.total && (
												<Pagination
													current={parseInt(GameList && GameList?.page) || 1}
													total={(GameList && GameList?.total) || 0}
													pageSize={GameList && GameList?.perPage}
													onChange={onPageChange}
													showSizeChanger
													showQuickJumper
													showTotal={(total, range) =>
														`${range[0]}-${range[1]} of ${total} items`
													}
													locale={paginationLocale}
													itemRender={(page, type, originalElement) => {
														if (type === "page") {
															return <span>{page}</span>;
														}
														return originalElement;
													}}
													className="custom-pagination"
												/>
										  )
										: ""}
								</Col>
							</Row>
						</div>
					</Spin>
				</>
			)}
			{/* {} */}
		</div>
	);
};
